import ApiServices from '@/services/api.service'

class FavoritesKashruyot {
    constructor() {
    }

    static async getUserFavoritesKashruyot() {

      try {
      const token = localStorage.getItem("token");
      let api = process.env.VUE_APP_BASE_URL + "/user/get_user_favorites_kashruyot";
      const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
      const jsonObject = await res.json();
      if (res.status === 400) {
          return [];
      } else if (res.status === 200) {
          return jsonObject.favorites_kashruyot
      }
    } catch (error) {
      return false;
    }
  }

    

}

export default FavoritesKashruyot;

