<template>
    <div>
        <div class="center-container" style="margin-top: 50px;">
            <v-autocomplete :items="arrayNames" style=" max-width: 500px" v-model="searchSelected"
                :label="'חפש לפי שם מסעדה, יישוב או מדינה'" outlined dense clearable append-icon="mdi-magnify"
                :menu-props="{ bottom: true, offsetY: true }">
            </v-autocomplete>
        </div>
        <div class="center-container">
            <v-text-field style=" max-width: 500px" v-model="idSelected" @change="getRestDetails('by_id')"
                label="חיפוש לפי ID"></v-text-field>
        </div>
        <div class="center-container">
            <v-card class="center-card" elevation="4" style="margin-bottom: 100px;">
                <div class="d-flex justify-end pt-3 pl-3">
                    <span class="text-center flex-grow-1"
                        style="font-weight: bold; font-size: 30px; font-family: Times, serif; color: #062a4e;">עריכת מסעדה
                    </span>
                </div>
                <v-card-text>
                    <v-container>
                        <v-form @submit.prevent="submitHandler" ref="form">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="itemEdited.name" label="שם" :rules="[v => !!v || 'שדה חובה']"
                                        required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="itemEdited.address" label="רחוב"
                                        :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-combobox :items="citiesItems" v-model="itemEdited.city_web" label="עיר" 
                                        :rules="[v => !!v || 'שדה חובה']" required></v-combobox>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-combobox :items="countriesItems" v-model="itemEdited.country_web"
                                        label="מדינה"></v-combobox>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-autocomplete :items="zonesItems" v-model="itemEdited.zone_web" label="אזור"
                                        :rules="[v => !!v || 'שדה חובה']" required></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="itemEdited.website" label="אתר"></v-text-field>
                                </v-col><v-col cols="12" sm="6">
                                    <v-text-field v-model="itemEdited.phone" @input="filterNumeric"
                                        label="טלפון"></v-text-field>
                                </v-col><v-col cols="12" sm="6">
                                    <v-autocomplete :items="categoriesItems" v-model="itemEdited.category_web"
                                        label="קטגוריה" :rules="[v => !!v || 'שדה חובה']" required></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-autocomplete :items="subCategoriesItems" multiple
                                        v-model="itemEdited.sub_category_web" label="תת קטגוריה"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-select :items="typesItems" multiple v-model="itemEdited.type_web" label="סוג"
                                        :rules="[v => !!v || 'שדה חובה']" required></v-select>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="itemEdited.details" label="מידע על המסעדה"></v-text-field>
                                </v-col>
                                <span style="font-weight: bold; font-size: large;">כשרות</span>
                                <v-col cols="12" sm="4">
                                    <v-autocomplete :items="kashrutItems" v-model="itemEdited.kashrut.kashrut_name_web"
                                        :rules="[v => !!v || 'שדה חובה']" required label="כשרות"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4"
                                    v-if="itemEdited.kashrut.kashrut_name_web === 'badatz_local'">
                                    <v-text-field v-model="itemEdited.kashrut.local_kashrut_name"
                                        label="כשרות מקומית"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field v-model="itemEdited.kashrut.kashrut_details"
                                        label="פרטים נוספים על הכשרות"></v-text-field>
                                </v-col>
                                <span style="font-weight: bold; font-size: large;">שעות פתיחה</span>
                                <v-col cols="auto" sm="auto">
                                    <span>יום א'</span>
                                    <v-text-field v-model="itemEdited.open_hours.sunday.open" type="time"
                                        label="פתיחה"></v-text-field>
                                    <v-text-field v-model="itemEdited.open_hours.sunday.close" type="time"
                                        label="שעות סגירה"></v-text-field>
                                </v-col>
                                <v-col cols="auto" sm="auto">
                                    <span>יום ב'</span>
                                    <v-text-field v-model="itemEdited.open_hours.monday.open" type="time"
                                        label="פתיחה"></v-text-field>
                                    <v-text-field v-model="itemEdited.open_hours.monday.close" type="time"
                                        label="שעות סגירה"></v-text-field>
                                </v-col>
                                <v-col cols="auto" sm="auto">
                                    <span>יום ג'</span>
                                    <v-text-field v-model="itemEdited.open_hours.tuesday.open" type="time"
                                        label="פתיחה"></v-text-field>
                                    <v-text-field v-model="itemEdited.open_hours.tuesday.close" type="time"
                                        label="שעות סגירה"></v-text-field>
                                </v-col>
                                <v-col cols="auto" sm="auto">
                                    <span>יום ד'</span>
                                    <v-text-field v-model="itemEdited.open_hours.wednesday.open" type="time"
                                        label="פתיחה"></v-text-field>
                                    <v-text-field v-model="itemEdited.open_hours.wednesday.close" type="time"
                                        label="שעות סגירה"></v-text-field>
                                </v-col>
                                <v-col cols="auto" sm="auto">
                                    <span>יום ה'</span>
                                    <v-text-field v-model="itemEdited.open_hours.thursday.open" type="time"
                                        label="פתיחה"></v-text-field>
                                    <v-text-field v-model="itemEdited.open_hours.thursday.close" type="time"
                                        label="שעות סגירה"></v-text-field>
                                </v-col>
                                <v-col cols="auto" sm="auto">
                                    <span>יום ו'</span>
                                    <v-text-field v-model="itemEdited.open_hours.friday.open" type="time"
                                        label="פתיחה"></v-text-field>
                                    <v-text-field v-model="itemEdited.open_hours.friday.close" type="time"
                                        label="שעות סגירה"></v-text-field>
                                </v-col>
                                <v-col cols="auto" sm="auto">
                                    <span>מוצ"ש</span>
                                    <v-text-field v-model="itemEdited.open_hours.saturday.open" type="time"
                                        label="פתיחה"></v-text-field>
                                    <v-text-field v-model="itemEdited.open_hours.saturday.close" type="time"
                                        label="שעות סגירה"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-checkbox v-model="itemEdited.open_hours.only_weekend" :label="weekendTitle"
                                        style="margin-bottom: 0px !important; margin-top: 0px !important;">
                                    </v-checkbox>
                                </v-col>
                                <span style="font-weight: bold; font-size: large;">מיקום</span>
                                <v-col cols="12" sm="4">
                                    <v-text-field v-model="itemEdited.location.latitude" label="קו אורך"
                                        :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field v-model="itemEdited.location.longitude" label="קו רוחב"
                                        :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-btn @click="chooseDeviceLocation()" rounded>או בחר מיקום שלי</v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <!-- Search Field -->
                                    <GmapPlaceInput @place_changed="setPlace" ref="gmapInput" />
                                    <!-- Google Maps -->
                                    <GmapMap ref="gmapRef" :center="center" :zoom="12" style="width: 100%; height: 400px"
                                        @click="mapClicked">
                                        <GmapMarker v-if="marker.position.lat && marker.position.lng"
                                            :position="marker.position" @click="markerClicked"></GmapMarker>
                                    </GmapMap>
                                </v-col>
                                <span style="font-weight: bold; font-size: large;">העלאת תמונות</span>
                                <v-row>
                                    <v-col cols="auto" sm="6" class="d-flex justify-end">

                                        <div v-if="!itemEdited.logo" style="max-width: 100%; width: 150px; height: 150px;">
                                            <v-file-input label="לוגו" filled height="150" prepend-icon=""
                                                v-model="itemEdited.logo" @change="handleFileUpload('logo', null)"
                                                accept="image/*" prepend-inner-icon="mdi-camera"></v-file-input>
                                        </div>
                                        <div v-else style="height: 150px; width: 150px; position: relative;">
                                            <div
                                                style="max-width: 100%; width: 150px; height: 100%; background-color: rgba(0, 0, 0, 0.06);">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon v-bind="attrs" v-on="on"
                                                            style=" z-index: 10; cursor: pointer;"
                                                            @click="handleDelete('logo', null)">
                                                            mdi-delete
                                                        </v-icon>
                                                    </template>
                                                    <span>Delete Image</span>
                                                </v-tooltip>
                                                <span style="margin-inline-start: 10px;">לוגו</span>
                                                <img contain
                                                    style="display: block; max-width: 100%; max-height: 100%; width: auto; height: auto; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                                                    :src="host + '/logo' + itemEdited.logo" alt="Preview"
                                                    transition="scale-transition">
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="auto" sm="6">
                                        <div v-if="!itemEdited.kashrut.certificate"
                                            style="max-width: 100%; width: 150px; height: 150px;">
                                            <v-file-input label="תעודת כשרות" filled height="150" prepend-icon=""
                                                v-model="itemEdited.kashrut.certificate"
                                                @change="handleFileUpload('certificate', null)" accept="image/*"
                                                prepend-inner-icon="mdi-camera"></v-file-input>
                                        </div>
                                        <div v-else style="height: 150px; width: 150px; position: relative;">
                                            <div
                                                style="max-width: 100%; width: 150px; height: 100%; background-color: rgba(0, 0, 0, 0.06);">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon v-bind="attrs" v-on="on"
                                                            style=" z-index: 10; cursor: pointer;"
                                                            @click="handleDelete('certificate', null)">
                                                            mdi-delete
                                                        </v-icon>
                                                    </template>
                                                    <span>Delete Image</span>
                                                </v-tooltip>
                                                <span style="margin-inline-start: 10px;">תעודת כשרות</span>
                                                <img contain
                                                    style="display: block; max-width: 100%; max-height: 100%; width: auto; height: auto; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                                                    :src="host + '/certificates' + itemEdited.kashrut.certificate"
                                                    alt="Preview" transition="scale-transition">
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                                <!-- handle upload picturs -->
                                <v-row>
                                    <v-col v-for="(picture, index) in arrayPictures" cols="auto" sm="3">
                                        <div style="max-width: 100%; width: 150px; height: 150px;"
                                            v-if="!itemEdited.pictures[index]">
                                            <v-file-input :label="'תמונה ' + (index + 1)" filled height="150"
                                                prepend-icon="" v-model="itemEdited.pictures[index]"
                                                @change="handleFileUpload('picture', index)" prepend-inner-icon="mdi-camera"
                                                accept="image/*">
                                            </v-file-input>
                                        </div>
                                        <div v-else style="height: 150px; width: 150px; position: relative;">
                                            <div
                                                style="max-width: 100%; width: 150px; height: 100%; background-color: rgba(0, 0, 0, 0.06);">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon v-bind="attrs" v-on="on"
                                                            style=" z-index: 10; cursor: pointer;"
                                                            @click="handleDelete('picture', index)">
                                                            mdi-delete
                                                        </v-icon>
                                                    </template>
                                                    <span>Delete Image</span>
                                                </v-tooltip>
                                                <span style="margin-inline-start: 10px;">תמונה {{ (index + 1) }}</span>
                                                <img v-if="itemEdited.pictures[index]" contain
                                                    style="display: block; max-width: 100%; max-height: 100%; width: auto; height: auto; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);"
                                                    :src="host + '/pictures' + itemEdited.pictures[index]" alt="Preview"
                                                    transition="scale-transition">
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-col cols="12" class="d-flex justify-center">
                                    <v-btn color="#062a4e" dark @click="editRestaurant()" rounded>ערוך מסעדה</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row v-if="progressShow">
                            <v-col class="d-flex justify-center">
                                <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </div>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    </div>
</template>
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar'
import Constants from '@/util/GetConstant'
import axios from 'axios'

export default {
    components: {
        SnackBar
    },
    data() {
        return {
            progressShow: false,
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
            itemEdited: {
                name: "",
                address: "",
                city: "",
                city_web: "",
                country: "",
                country_web: "",
                zone: "",
                zone_web: "",
                type: [],
                type_web: [],
                phone: "",
                website: "",
                category: "",
                category_web: "",
                sub_category: [],
                sub_category_web: [],
                details: "",
                pictures: [],
                logo: null,
                kashrut: { kashrut_name: "", kashrut_name_web: "", local_kashrut_name: "", kashrut_details: "", certificate: null },
                open_hours: {
                    sunday: { open: "", close: "" }, monday: { open: "", close: "" }, tuesday: { open: "", close: "" },
                    wednesday: { open: "", close: "" }, thursday: { open: "", close: "" }, friday: { open: "", close: "" }, saturday: { open: "", close: "" },
                    only_weekend: false,
                },
                location: { latitude: "", longitude: "" }
            },
            objKashruyot: {},
            objTypes: {},
            objCategories: {},
            objSubCategories: {},
            objZones: {},
            objCountries: {},
            objCities: {},
            arrayNames: [],
            autoCompleteList: [],
            searchSelected: "",
            idSelected: "",
            arrayPictures: ["", "", "", ""],
            host: "",
            weekendTitle: "פתוח רק בסופ\"ש",
            center: { lat: 32.0879976, lng: 34.8384455 },
            marker: {
                position: { lat: 32.0879976, lng: 34.8384455 }
            }

        }
    },
    methods: {

        async editRestaurant() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");

                this.itemEdited.city_web = this.itemEdited.city_web.value;
                this.itemEdited.country_web = this.itemEdited.country_web ? this.itemEdited.country_web.value : "";
                const restaurantJSON = JSON.stringify({ rest: this.itemEdited })
                let api = process.env.VUE_APP_BASE_URL + `/admin/edit_restaurant`;
                const res = await fetch(api, ApiServices.requestOptions("POST", restaurantJSON, token));
                this.progressShow = false;
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בעריכת מסעדה : " + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.showSnackBar("המסעדה נערכה בהצלחה!", "green");
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error save new restaurant: " + error, "red");
            }
        },
        async getRestDetails(by) {
            try {
                this.progressShow = true;
                let nameSearchJSON;
                if (by === "by_id") {
                    nameSearchJSON = JSON.stringify({ idSelected: this.idSelected });
                } else {
                    nameSearchJSON = JSON.stringify({ searchSelected: this.searchSelected });
                }
                const token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/admin/get_rest_for_edit`;

                const res = await fetch(api, ApiServices.requestOptions("POST", nameSearchJSON, token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים: " + jsonObject.message, "red");
                    this.progressShow = false;
                } else if (res.status === 200) {
                    this.itemEdited = jsonObject;
                    this.progressShow = false;
                    console.log(this.itemEdited);
                    this.updateMarkerPosition(jsonObject);
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get restaurants list: " + error, "red");
            }
        },
        updateMarkerPosition(item) {
            const latitude = parseFloat(item.location.latitude);
            const longitude = parseFloat(item.location.longitude);

            // Ensure the latitude and longitude are valid numbers
            if (isFinite(latitude) && isFinite(longitude)) {
                // Update marker position with valid latitude and longitude
                this.marker.position = {
                    lat: latitude,
                    lng: longitude
                };

                // Optionally, move the map to center on the new marker position
                this.$refs.gmapRef.$mapObject.panTo(this.marker.position);
            } else {
                console.error("Invalid latitude or longitude values:", latitude, longitude);
            }
        },
        chooseDeviceLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        this.showPosition(position);
                        { true }
                    },
                    error => {
                        this.showSnackBar("שגיאה בקבלת המיקום: יש להפעיל את המיקום בלחיצה על האייקון למעלה בשורת הכתובת", "red");
                    }
                );
            } else {
                this.showSnackBar("Geolocation is not supported", "red");
            }
        },
        showPosition(position) {
            this.itemEdited.location.latitude = position.coords.latitude;
            this.itemEdited.location.longitude = position.coords.longitude;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        async getConstants() {
            const constants = await Constants.getConstant();
            if (!constants) {
                this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
            }
            this.objKashruyot = constants.kashruyot
            this.objTypes = constants.types;
            this.objCategories = constants.categories;
            this.objSubCategories = constants.sub_categories;
            this.objZones = constants.zones
            this.objCountries = constants.countries
            this.objCities = constants.cities
        },
        async getAutoComplete() {
            const constants = await Constants.getSearchAutoCompleteList();
            if (!constants) {
                this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
            }
            this.arrayNames = constants.map(constant => constant.name);
        },
        handleFileUpload(type, index) {
            if (type === "certificate") {
                if (this.itemEdited.kashrut.certificate) {
                    this.uploadOrDeleteImage("upload", "certificate", null, this.itemEdited.kashrut.certificate);
                }
            } else if (type === "logo") {
                if (this.itemEdited.logo) {
                    this.uploadOrDeleteImage("upload", "logo", null, this.itemEdited.logo);
                }
            } else if (type === "picture") {
                if (this.itemEdited.pictures[index]) {
                    this.uploadOrDeleteImage("upload", "picture", index, this.itemEdited.pictures[index]);
                }
            }
        },
        handleDelete(type, index) {
            if (type === "certificate") {
                this.uploadOrDeleteImage("delete", "certificate", null, null);
                this.itemEdited.kashrut.certificate = null;
            } else if (type === "logo") {
                this.uploadOrDeleteImage("delete", "logo", null, null);
                this.itemEdited.logo = null;
            } else if (type === "picture") {
                this.uploadOrDeleteImage("delete", "picture", index, null);
                this.itemEdited.pictures[index] = null;
            }
        },
        async uploadOrDeleteImage(action, type, index, image) {

            let message = "הקובץ נשמר בהצלחה!"
            if (action === "delete") {
                message = "הקובץ נמחק בהצלחה!"
            }
            if (!this.itemEdited._id) {
                return this.showSnackBar('יש לבחור מסעדה לפני העלאת קובץ', 'red');
            }
            try {
                this.progressShow = true;
                const token = localStorage.getItem('token');
                let api = process.env.VUE_APP_BASE_URL + `/admin/handle_image`;
                const formData = new FormData();
                formData.append('image', image);
                formData.append('action', action);
                formData.append('type', type);
                formData.append('index', index);
                formData.append('restId', this.itemEdited._id);
                const response = await axios.post(api, formData, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (action === 'upload') {
                    if (type === "logo") {
                        this.itemEdited.logo = response.data.newLink
                    }
                    if (type === "certificate") {
                        this.itemEdited.kashrut.certificate = response.data.newLink
                    }
                    if (type === "picture") {
                        this.itemEdited.pictures[index] = response.data.newLink
                    }
                }
                this.showSnackBar(message, 'green');
                this.progressShow = false;
            } catch (error) {
                this.showSnackBar(' שגיאה בשמירת הקובץ' + error, 'red');
                this.progressShow = false;
            }

        },
        setPlace(place) {
            this.marker.position = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };
            this.center = this.marker.position;
        },
        markerClicked(event) {
            alert(`Marker clicked! Latitude: ${event.latLng.lat()} Longitude: ${event.latLng.lng()}`);
        },
        mapClicked(event) {

            this.marker.position = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            };

            this.itemEdited.location.latitude = event.latLng.lat();
            this.itemEdited.location.longitude = event.latLng.lng();
        },
        filterNumeric(value) {
            console.log(value);
            // Replace non-numeric characters with an empty string
            this.itemEdited.phone = value.replace(/\D+/g, '');
        }
    },
    mounted() {
        this.getConstants();
        this.getAutoComplete();
        this.host = process.env.VUE_APP_BASE_URL;
        if (this.$route.params._id) {
            this.idSelected = this.$route.params._id
            this.getRestDetails('by_id');
        }
    },
    computed: {
        kashrutItems() {
            return Object.entries(this.objKashruyot).map(([key, kashrut]) => ({
                value: key, // You can use the key as the value
                text: kashrut[this.$i18n.locale] || kashrut.en // or value.en for English
            }));
        },
        zonesItems() {
            return Object.entries(this.objZones).map(([key, zone]) => ({
                value: key, // You can use the key as the value
                text: zone[this.$i18n.locale] || zone.en // or value.en for English
            }));
        },
        typesItems() {
            return Object.entries(this.objTypes).map(([key, type]) => ({
                value: key, // You can use the key as the value
                text: type[this.$i18n.locale] || type.en // or value.en for English
            }));
        },
        categoriesItems() {
            return Object.entries(this.objCategories).map(([key, category]) => ({
                value: key, // You can use the key as the value
                text: category[this.$i18n.locale] || category.en // or value.en for English
            }));
        },
        subCategoriesItems() {
            return Object.entries(this.objSubCategories).map(([key, subCategory]) => ({
                value: key, // You can use the key as the value
                text: subCategory[this.$i18n.locale] || subCategory.en // or value.en for English
            }));
        },
        countriesItems() {
            return Object.entries(this.objCountries).map(([key, country]) => ({
                value: key, // You can use the key as the value
                text: country[this.$i18n.locale] || city.en // or value.en for English
            }));
        },
        citiesItems() {
            return Object.entries(this.objCities).map(([key, city]) => ({
                value: key, // Use the city key as the value
                text: city[this.$i18n.locale] || city.en // Use localized city name
            }));
        }
    },
    watch: {
        searchSelected() {
            if (this.searchSelected) {
                this.getRestDetails("by_name")
            }
        },
        'itemEdited.location.latitude': function (newLat) {
            this.updateMarkerPosition(this.itemEdited);
        },
        'itemEdited.location.longitude': function (newLng) {
            this.updateMarkerPosition(this.itemEdited);
        }
    },


};
</script>
<style scoped>
.center-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-right: 5px;
    margin-left: 5px;
}

.center-card {
    max-width: 900px;
    width: 100%;
    padding-bottom: 40px;
    margin-left: 5px;
    margin-right: 5px;
}
</style>