<template>
    <v-row class="d-flex justify-center" style="margin-right: 0px!important; margin-right: 0px!important;">

        <v-col cols="auto" sm="6" md="4" v-for="(item, index) in restaurants" >

            <v-hover v-slot="{ hover }" open-delay="100">

                <v-card class="mb-5 mt-5 mx-auto cardStyle" :id="'card-' + index" :elevation="hover ? 16 : 2"
                    style="border-radius: 15px !important;">

                    <clazy-load :src="host + '/logo' + item.logo" v-if="item.logo" style="cursor: pointer;">
                        <v-img :src="host + '/logo' + item.logo" height="190px" @click="showDetails(item, 'card-' + index)"
                            style="border-top-left-radius: 15px; border-top-right-radius: 15px;">
                            <div v-if="item.open_hours.only_weekend">
                                <span
                                    style="margin: 3px; padding: 3px; font-weight: 600; font-size: small; background-color: antiquewhite; border-radius: 5px;">
                                    {{ $t('card.open_only_weekend') }}
                                </span>
                            </div>
                            <div v-if="isPromotionActive(item)">
                                <span
                                    style="margin: 3px; padding: 3px; font-weight: 600; font-size: small; background-color: #f0b6ae73; border-radius: 5px;">
                                    {{ $t('card.promoted') }}
                                </span>
                            </div>
                            <div style="height: 32px; background-color: rgba(255, 255, 255, 0.699);  width: 100%; 
        display: flex; justify-content: center; align-items: flex-end; position: absolute; bottom: 0;">
                                <span style="font-size: 21px; color: #062a4e; font-weight: bold; padding-right: 5px;">{{
                                    item.name
                                }}</span>
                            </div>
                        </v-img>
                        <div slot="placeholder" class="d-flex justify-center"
                            style="padding-top: 10px; padding-bottom: 10px;">
                            {{ $t('card.loading') }}
                        </div>
                    </clazy-load>

                    <div v-else style="padding-top: 10px; cursor: pointer;" @click="showDetails(item, 'card-' + index)">
                        <v-img height="140px" src="@/assets/default_logo_clear.png" contain>
                            <div v-if="item.open_hours.only_weekend">
                                <span
                                    style="margin: 3px; padding: 3px; font-weight: 600; font-size: small; background-color: antiquewhite; border-radius: 5px;">
                                    {{ $t('card.open_only_weekend') }}
                                </span>
                            </div>
                            <div v-if="isPromotionActive(item)">
                                <span
                                    style="margin: 3px; padding: 3px; font-weight: 600; font-size: small; background-color: #f0b6ae73; border-radius: 5px;">
                                    {{ $t('card.promoted') }}
                                </span>
                            </div>
                        </v-img>
                        <div style="height: 40px; background-color: rgba(209, 208, 208, 0.075);  width: 100%;"
                            class="d-flex justify-center">
                            <span style="font-size: 21px; color: #062a4e; font-weight: bold; padding-right: 5px;">{{
                                item.name
                            }}</span>
                        </div>
                    </div>

                    <div :style="getBackgroundColor(item)">
                        <div
                            style="height: 230px; margin-left: 8px; margin-right: 8px; padding-right: 10px; padding-left: 10px">

                            <div class="d-flex justify-space-between align-center" style="margin-bottom: 5px;">
                                <div class="d-flex align-center">
                                    <v-rating :value="calculateRates(item.rates.ratings)" readonly
                                        background-color="yellow accent-4" color="yellow accent-4" dense half-increments
                                        hover size="18"></v-rating>
                                    <span class=" text-caption mr-1">
                                        ({{ item.rates.totalRatings }})
                                    </span>
                                </div>
                                <div style="padding: 5px;" :style="setMarginLeft(10)" >
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" @click="addOrRemoveToFavorite(item, isFavorite(item))"
                                                :color="isFavorite(item) ? 'red' : 'default'">
                                                {{ isFavorite(item) ? 'mdi-heart' : 'mdi-heart-outline' }}
                                            </v-icon>
                                        </template>
                                        <span>{{ $t('card.add_to_favorites') }}</span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <div class="d-flex justify-space-between" style="margin-bottom: 5px;">
                                <div class="d-flex">
                                    <!-- Category Icon -->
                                    <v-icon medium color="rgb(255, 174, 0)" :style="setMarginLeft(5)">{{
                                        getCategoryIcon(item.category_web) }}</v-icon>
                                    <span>{{ constants.categories[item.category_web][$i18n.locale] }} </span>
                                </div>
                                <div class="d-flex" v-if="item.type_web[1] && !item.type_web[2]">
                                    <span class="typeStyle" :style="{ background: getBackColorType(item.type_web[0]) }">
                                        {{ constants.types[item.type_web[0]][$i18n.locale] }}
                                    </span>
                                    <span class="typeStyle"
                                        :style="{marginRight: $vuetify.rtl ? '5px' : '0px', marginLeft: $vuetify.rtl ? '0px' : '5px',
                                         background: getBackColorType(item.type_web[1]) }">
                                        {{ constants.types[item.type_web[1]][$i18n.locale] }}
                                    </span>
                                </div>
                                <div class="d-flex" v-else-if="item.type_web[1] && item.type_web[2]">
                                    <span class="typeStyle" :style="{ background: getBackColorType(item.type_web[0]) }">
                                        {{ constants.types[item.type_web[0]][$i18n.locale] }}
                                    </span>
                                    <span class="typeStyle" 
                                    :style="{ marginRight: $vuetify.rtl ? '5px' : '0px', marginLeft: $vuetify.rtl ? '0px' : '5px', 
                                     background: getBackColorType(item.type_web[1]) }">
                                        {{ constants.types[item.type_web[1]][$i18n.locale] }}
                                    </span>
                                    <span class="typeStyle" style="margin-right: 5px; margin-left: 5px;"
                                        :style="{ background: getBackColorType(item.type_web[2]) }">
                                        {{ constants.types[item.type_web[2]][$i18n.locale] }}
                                    </span>
                                </div>
                                <span v-else class="typeStyle" :style="{ background: getBackColorType(item.type_web[0]) }">
                                    {{ constants.types[item.type_web[0]][$i18n.locale] }}
                                </span>
                            </div>

                            <div style="font-size: small; color: grey; margin-top: 5px;">
                                <span>{{ $t('card.kashrut') }}</span>
                            </div>
                            <div style="padding-left: 10px;" class="d-flex justify-space-between">
                                <span
                                    v-if="item.kashrut.kashrut_details && constants.kashruyot[item.kashrut.kashrut_name_web][$i18n.locale] !== $t('card.else')">
                                    {{ constants.kashruyot[item.kashrut.kashrut_name_web][$i18n.locale] }} + 1
                                </span>
                                <span v-else>{{ constants.kashruyot[item.kashrut.kashrut_name_web][$i18n.locale] }}</span>
                                <span v-if="location.latitude">
                                    <v-icon small color="#808080">
                                        mdi-navigation-variant
                                    </v-icon>
                                    {{ calculateDistance(item) }}
                                </span>
                            </div>

                            <div style="font-size: small; color: grey; margin-top: 5px;">
                                <span>{{ $t('card.address') }}</span>
                            </div>
                            <div>
                                <span>{{ item.address }} </span>
                            </div>
                            <div style="padding-left: 10px; padding-top: 5px;" class="d-flex justify-space-between">
                                <span>{{ constants.cities?.[item.city_web]?.[$i18n.locale] }} </span>
                                <span>{{ constants.countries?.[item.country_web]?.[$i18n.locale] || '' }} </span>
                            </div>
                            <div @click="showDetails(item, 'card-' + index)" class="d-flex justify-center align-center"
                                style="position: absolute; bottom: 5px; right: 30%; font-size: small;
           left: 30%; background-color: #9c929227; border-radius: 7px; cursor: pointer;">
                                <span style="">{{ $t('card.full_details') }}</span>
                            </div>
                        </div>
                    </div>
                </v-card>


            </v-hover>
        </v-col>
    </v-row>
</template>
<script>
import Auth from '@/services/auth.service.js'
import Favorites from '@/util/Favorites'
import Distance from '@/util/Distance'

export default {
    name: "CardComponent",
    props: {
        restaurants: Array,
        userFavorites: Array,
        location: Object,
        host: String,
        constants: Object
    },
    data() {
        return {
            currentScrollTop: 0 // For page scroll
        }
    },
    methods: {

        isPromotionActive(item) {
            const currentDate = new Date();
            return item.promotion.isPromotion && new Date(item.promotion.dateEnd) > currentDate;
        },
        showDetails(item) {
            this.$store.commit('currentScrollTop', window.scrollY); // For page scroll
            this.$store.commit('itemsPerLoad', this.restaurants.length); // For page scroll
            this.$router.push({ name: 'restaurant-details', params: { id: item._id } });
        },
        getBackgroundColor(item) {
            const currentDate = new Date();
            const isPromotionValid = item.promotion.isPromotion && new Date(item.promotion.dateEnd) > currentDate;
            return isPromotionValid ? { 'background-color': '#f0b6ae73' } : { 'background-color': '#f6f6f6' };
        },
        calculateRates(ratings) {
            const sum = ratings.reduce((total, rating) => total + rating.rate, 0);
            const average = sum / ratings.length;
            return average;
        },
        async addOrRemoveToFavorite(item, isFavorite) {

            let auth = Auth.checkAuth();
            if (!auth) {
                // To save a favorite in local storage
                if (!isFavorite) {
                    const favorites = JSON.parse(localStorage.getItem('favorites') || '[]');
                    favorites.push(item._id);
                    localStorage.setItem('favorites', JSON.stringify(favorites));
                    this.$emit('emitShowSnackBar', "נוסף למועדפים", "green");
                    this.$emit('reloadFavorites');
                    return
                } else {
                    // If it's a favorite, remove from local storage
                    const updatedFavorites = JSON.parse(localStorage.getItem('favorites') || '[]').filter(fav => fav !== item._id);
                    localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
                    this.$emit('emitShowSnackBar', "הוסר מהמועדפים", "green");
                    this.$emit('reloadFavorites');
                    return
                }
            }

            const addToFavorite = await Favorites.addOrRemoveToFavorite(JSON.stringify({ item: item, isFavorite: isFavorite }));
            if (addToFavorite === "added") {
                this.$emit('emitShowSnackBar', "נוסף למועדפים", "green");
                this.$emit('reloadFavorites');
            } else if (addToFavorite === "removed") {
                this.$emit('emitShowSnackBar', "הוסר מהמועדפים", "green");
                this.$emit('reloadFavorites');
            } else {
                this.$emit('emitShowSnackBar', "שגיאה בהוספה למועדפים", "red");
            }
        },
        isFavorite(item) {
            return this.userFavorites.includes(item._id);
        },
        getCategoryIcon(category) {
            switch (category) {
                case 'restaurant': return 'mdi-silverware';
                case 'fast_food': return 'mdi-hamburger';
                case 'bakery': return 'mdi-baguette';
                case 'supermarket': return 'mdi-cart';
                default: return 'mdi-store';
            }
        },
        getBackColorType(type) {
            return type === "meat" ? "rgba(255, 0, 0, 0.521)" : type === "dairy" ? "rgba(2, 2, 173, 0.514)" : "rgba(0, 128, 0, 0.527)";
        },
        calculateDistance(item) {
            let dist;
            if (item.distance) {
                dist = item.distance
            } else {
                dist = Distance.checkDistance(this.location, item.location);
            }
            if (dist < 1000) {
                return `${dist} ${this.$t('card.meter')}`;
            } else {
                const firstPart = Math.floor(dist / 1000); // Get the first part of the distance
                const secondPart = Math.floor((dist % 1000) / 100); // Get the second part of the distance

                return `${firstPart}.${secondPart} ${this.$t('card.kilometer')}`;
            }

        },
        setMarginRigth(pixels) {
            return this.$vuetify.rtl ? `margin-right: ${pixels}px` : `margin-left: ${pixels}px`;
        },
        setMarginLeft(pixels) {
            return this.$vuetify.rtl ? `margin-left: ${pixels}px` : `margin-right: ${pixels}px`;
        },
    },
    mounted() {
       
    },
}
</script>
<style>
.cardStyle {
    width: 370px;
    max-width: 100%;
    height: 420px;
}

.typeStyle {
    border-radius: 80px;
    padding-left: 2%;
    padding-right: 2%;
    color: white;
}
</style>
   