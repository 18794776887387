<template>
  <v-main>
    <v-app-bar color="rgb(255, 255, 255)" fixed :class="{ 'app-bar': true, 'shrink': isAppBarShrunk }"
      :height="appBarHeight">
      <v-toolbar-title :style="getTitleStyles">
        <div class="iconNavBar">
          <v-img alt="Vuetify Logo" src="@/assets/default_logo.png" contain transition="scale-transition"
            style="height: 100%;" />

        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down ms-3">
        <v-btn v-for="item in menu" :key="item.id" :to="item.link" text v-if="item.show" class="i" :class="{ 'active-link': selectedItem === item.id }"
          style=" font-size: 29px; font-weight: bold; color: #062a4e;" @click="selectedItem = item.id">
          <v-icon left medium>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <div class="d-flex justify-end pt-3 pl-3">
        <div class="hidden-sm-and-down" style="display: flex; justify-content: center; align-items: center;"
          :style="setMarginLeft(10)">
          <!-- Menu with Language Options -->
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-img :src="currentFlag" contain max-width="30" />
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="changeLanguage('en')">
                <v-list-item-icon>
                  <!-- Use v-img to load image from assets -->
                  <v-img :src="require('@/assets/flag_uk.png')" max-width="24" max-height="24" />
                </v-list-item-icon>
                <v-list-item-title>{{ $t('nav.lang_en') }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeLanguage('he')">
                <v-list-item-icon>
                  <!-- Use v-img to load image from assets -->
                  <v-img :src="require('@/assets/flag_israel.png')" max-width="24" max-height="24" />
                </v-list-item-icon>
                <v-list-item-title>{{ $t('nav.lang_he') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div class="user-info hidden-sm-and-down" style="display: flex; justify-content: center; align-items: center;">
          <span class="mr-2" style="font-weight: 500; font-size: 14px; color: #062a4e;">{{ userName }}</span>
          <span class="mr-2" style="font-size: 14px; color: #062a4e;" @click="showLogs()">{{ email }}</span>
        </div>

        <div class="hidden-sm-and-down" style="display: flex; justify-content: center; align-items: center;">
          <div class="logout-button-container" :style="setMarginLeft(5)">
            <v-tooltip bottom v-if="isLoggedIn">
              <template v-slot:activator="{ on }">
                <div @mouseover="isFocused = true" @mouseleave="isFocused = false" @click="logOut()" v-on="on">
                  <v-img src="../../assets/log-out.svg" :style="{
                    width: isFocused ? '32px' : '24px', height: isFocused ? '32px' : '24px',
                    marginRight: $vuetify.rtl ? '10px' : '0px',
                    marginLeft: !$vuetify.rtl ? '10px' : '0px',
                    marginTop: '5px'
                  }" class="image-transition" />
                </div>
              </template>
              <span style="">{{$t('nav.logout')}}</span>
            </v-tooltip>
            <v-btn v-else @click="login()" dark color="#062a4e" style="text-transform: none;">
              {{$t('nav.login')}}</v-btn>
          </div>
        </div>
      </div>

      <div class="hidden-md-and-up">

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-img :src="currentFlag" contain max-width="30" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="changeLanguage('en')">
              <v-list-item-icon>
                <!-- Use v-img to load image from assets -->
                <v-img :src="require('@/assets/flag_uk.png')" max-width="24" max-height="24" />
              </v-list-item-icon>
              <v-list-item-title>{{ $t('nav.lang_en') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeLanguage('he')">
              <v-list-item-icon>
                <!-- Use v-img to load image from assets -->
                <v-img :src="require('@/assets/flag_israel.png')" max-width="24" max-height="24" />
              </v-list-item-icon>
              <v-list-item-title>{{ $t('nav.lang_he') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-app-bar-nav-icon></v-app-bar-nav-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list>
              <v-list-item v-for="item in menu" :key="item.id" :to="item.link">
                <v-list-item-icon>
                  <v-icon style="color: #062a4e;">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
            <v-list-item v-if="isLoggedIn" :key="'logout'" @click="logOut()">
              <v-list-item-action>
                <v-img src="@/assets/log-out.svg" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{$t('nav.logout')}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else @click="login()">
              <v-list-item-action>
                <v-img src="@/assets/log-out.svg" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{$t('nav.login')}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

    </v-app-bar>

  </v-main>
</template>
  
<script>
import Auth from '@/services/auth.service.js'

export default {
  data() {
    return {
      menu: [],
      isFocused: false,
      overOnItem: null,
      selectedNavItem: null,
      isAppBarShrunk: false, // Flag to track if the app bar is shrunk
      appBarHeight: 98, // Default height
      userName: "",
      email: "",
      isFocused: false,
      isUser: false,
      isAdmin: false,
      isLoggedIn: false,
      menuLang: false,
      currentFlag: this.$vuetify.rtl ? require('@/assets/flag_israel.png') : require('@/assets/flag_uk.png'), // Default UK flag
      selectedItem: "home", // To track the selected menu item
    }

  },
  methods: {
    handleScroll() {
      // Calculate the scroll position
      const scrollY = window.scrollY || window.pageYOffset;

      // Update the isAppBarShrunk flag based on the scroll position
      this.isAppBarShrunk = scrollY > 0;
    },
    updateAppBarHeight() {
      // Update the appBarHeight based on the screen width
      this.appBarHeight = window.innerWidth < 600 ? 50 : 98;
    },
    logOut() {
      localStorage.removeItem("token")
      this.isAdmin = false;
      this.isUser = false;
      this.isLoggedIn = false
      this.userName = null;
      this.email = null;
      this.setNavListIItems();
    },
    login() {
      this.$store.commit('setLoggedIn', false);
      this.$router.push({ name: "login" })
    },
    setNavListIItems() {
      if (Auth.checkAuth()) {
        this.userName = Auth.getUserName();
        this.email = Auth.getUserEmail();
        this.role = Auth.getUserRole();
        if (this.role === "admin") {
          this.menu = this.adminMenu;
          this.isAdmin = true
          this.isLoggedIn = true
        } else if (this.role === "user") {
          this.menu = this.userMenu;
          this.isUser = true
          this.isLoggedIn = true
        }
      } else {
        this.isLoggedIn = false
        this.menu = this.regularMenu;
      }
    },
    showLogs() {
      if (this.role === "admin") {
        this.$router.push({ name: 'logs' })
      }
    },
    changeLanguage(langChoosed) {

        // Redirect to the appropriate domain based on the language
    if (langChoosed === 'en') {
      const currentPath = window.location.pathname;
      const enSubdomain = `https://en.kosher-basviva.com${currentPath}`;
      window.location.href = enSubdomain;
    } else if (langChoosed === 'he') {
      const currentPath = window.location.pathname;
      const mainDomain = `https://kosher-basviva.com${currentPath}`;
      window.location.href = mainDomain;
    }
    },
    setMarginRigth(pixels) {
      return this.$vuetify.rtl ? `margin-right: ${pixels}px` : `margin-left: ${pixels}px`;
    },
    setMarginLeft(pixels) {
      return this.$vuetify.rtl ? `margin-left: ${pixels}px` : `margin-right: ${pixels}px`;
    },
    setLocale(text) {
      return this.$t('nav.' + text);
    }
  },
  watch: {
  },
  created() {
    this.setNavListIItems();
    window.addEventListener('scroll', this.handleScroll);
    // Update appBarHeight initially
    this.updateAppBarHeight();
    // Listen for window resize to update appBarHeight
    window.addEventListener('resize', this.updateAppBarHeight);
    // הוספת קו תחתון במידה ועושים ריענון לדף
    // this.menu.forEach((item, index) => {
    //   if (this.$route.path === item.link) {
    //     this.overOnItem = index;
    //     this.selectedNavItem = index;
    //   }
    // });


  },
  beforeDestroy() {
    // Remove scroll event listener when the component is about to be destroyed
    window.removeEventListener('scroll', this.handleScroll);
    // Remove window resize event listener
    window.removeEventListener('resize', this.updateAppBarHeight);
  },
  computed: {
    getTitleStyles() {
      return this.$vuetify.rtl ? {
        'margin-left': '10%',
        'height': this.appBarHeight + 'px' // Set height dynamically
      } :
        {
          'margin-right': '10%',
          'height': this.appBarHeight + 'px' // Set height dynamically
        };
    },
    regularMenu() {
      return [
        { id: "home", title: this.$t('nav.li_item_rest_list'), link: "/", show: true, icon: "mdi-home" },
        { id: "about", title: this.$t('nav.li_item_about'), link: "/about", show: true, icon: "mdi-information-variant-circle" },
      ]
    },
    userMenu() {
      return [
        { id: "home", title: this.$t('nav.li_item_rest_list'), link: "/", show: true, icon: "mdi-home" },
        { id: "about", title: this.$t('nav.li_item_about'), link: "/about", show: true, icon: "mdi-information-variant-circle" },
        { id: "user", title: this.$t('nav.li_item_user_account'), link: "/user-account", show: true, icon: "mdi-account" },
      ]
    },
    adminMenu() {
      return [
        { id: "home", title: this.$t('nav.li_item_rest_list'), link: "/", show: true, icon: "mdi-home" },
        { id: "about", title: this.$t('nav.li_item_about'), link: "/about", show: true, icon: "mdi-information-variant-circle" },
        { id: "manager", title: this.$t('nav.li_item_admin'), link: "/admin-account", show: true, icon: "mdi-account" },
        { id: "statistics", title: this.$t('nav.li_item_statistics'), link: "/admin-statistics", show: true, icon: "mdi-poll" },
      ]
    }
  }
}
</script>
<style scoped>
.i {
  font-family: 'Open Sans Hebrew';
  text-transform: none;
}

.text-color-red {
  color: rgb(202, 4, 4) !important;
  font-weight: 700;
}

.active-link {
  position: relative;
  border-bottom: 3px solid #062a4e; /* Adjust color and thickness as needed */
  padding-bottom: 5px; /* Adjust this if you need more space for the underline */
}

.text-color-grey {
  color: #6a707e
}

.image-transition {
  transition: width 0.3s ease, height 0.3s ease;
  /* Adjust the animation duration and easing as needed */
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 0px;
}

.iconNavBar {
  /* background-color: #0099cd !important; */
  width: 147px;
  display: flex;
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
  height: 100%;
  /* Make sure the container takes full height */
}

.user-info {
  display: flex;
  flex-direction: column;
}


.logout-button-container {
  display: flex;
  align-items: center;
}
</style>
