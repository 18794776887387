import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        selectedNavItem: null,
        textColor: "#062a4e",
        constant: {},
        isLoggedIn: false,
        currentScrollTop: 0,
        itemsPerLoad: 20,
    },
    mutations: {
        setLoggedIn(state, status) {
            state.isLoggedIn = status;
        },
        currentScrollTop(state, payload) {
            state.currentScrollTop = payload;
        },
        itemsPerLoad(state, payload) {
            state.itemsPerLoad = payload;
        },
    }
})