
class FilterResaults {
    constructor() {
    }

    static filterResults(list, filteredChoosed) {
        let filtersParams = {
            fs_categories: '',
            fs_sub_category: '',
            fs_types: '',
            fs_kashrut: '',
        };
    
        // Helper function to extract selected keys from the objects
        const getSelectedKeys = (obj) => Object.keys(obj).filter(key => obj[key]);
    
        // Get selected categories
        const selectedCategories = getSelectedKeys(filteredChoosed.categories);
        if (selectedCategories.length) {
            list = list.filter(item => selectedCategories.includes(item.category));
            filtersParams.fs_categories = selectedCategories.join(',');
        }
    
        // Get selected sub-categories
        const selectedSubCategories = getSelectedKeys(filteredChoosed.sub_categories);
        if (selectedSubCategories.length) {
            list = list.filter(item =>
                item.sub_category.some(subCat =>
                    selectedSubCategories.includes(subCat)
                )
            );
            filtersParams.fs_sub_category = selectedSubCategories.join(',');
        }
    
        // Get selected types
        const selectedTypes = getSelectedKeys(filteredChoosed.types);
        if (selectedTypes.length) {
            list = list.filter(item =>
                item.type.some(typeItem =>
                    selectedTypes.includes(typeItem)
                )
            );
            filtersParams.fs_types = selectedTypes.join(',');
        }
    
        // Get selected kashruyot
        const selectedKashruyot = getSelectedKeys(filteredChoosed.kashruyot);
        if (selectedKashruyot.length) {
            list = list.filter(item =>
                selectedKashruyot.includes(item.kashrut.kashrut_name)
            );
            filtersParams.fs_kashrut = selectedKashruyot.join(',');
        }
    
        return { list, filtersParams };
    }
    
    // static filterResults(list, filteredChoosed) {
    //     let filtersParams = {
    //         fs_categories: '',
    //         fs_sub_category: '',
    //         fs_types: '',
    //         fs_kashrut: '',
    //     }; 
        
    //     if (!filteredChoosed.categories.every(item => item === null || item === 'empty' || item === '')) {
    //         list = list.filter(item => filteredChoosed.categories.includes(item.category));
    //         filtersParams.fs_categories = filteredChoosed.categories.join(',');
    //     } 
    //     if (!filteredChoosed.sub_categories.every(item => item === null || item === 'empty' || item === '')) {
    //         list = list.filter(item => 
    //             item.sub_category.some(typeItem => 
    //                 filteredChoosed.sub_categories.includes(typeItem)
    //             )
    //         );
    //         filtersParams.fs_sub_category = filteredChoosed.sub_categories.join(',');
    //     } 
    //     if (!filteredChoosed.types.every(item => item === null || item === 'empty' || item === '')) {
    //         list = list.filter(item => 
    //             item.type.some(typeItem => 
    //                 filteredChoosed.types.includes(typeItem) 
    //             )
    //         );
    //         filtersParams.fs_types = filteredChoosed.types.join(',');
    //     } 
    //     if (!filteredChoosed.kashruyot.every(item => item === null || item === 'empty' || item === '')) {
    //         list = list.filter(item => filteredChoosed.kashruyot.includes(item.kashrut.kashrut_name));
    //         filtersParams.fs_kashrut = filteredChoosed.kashruyot.join(',');
    //         console.log("test", filtersParams.fs_kashrut);
    //         console.log("test2", filteredChoosed.kashruyot);

    //     } 
    //     return {list, filtersParams };
    // }

}

export default FilterResaults;



    // if (filteredChoosed.categories !== 'הצג הכל') {
    //     list = list.filter(item => item.category === filteredChoosed.categories);
    // } 
    // if (filteredChoosed.sub_categories !== 'הצג הכל') {
    //     list = list.filter(item => item.sub_category === filteredChoosed.sub_categories);
    // } 
    // if (filteredChoosed.types !== 'הצג הכל') {
    //     list = list.filter(item => item.type.some(type => type === filteredChoosed.types));
    // } 
    // if (filteredChoosed.kashruyot[1]) {
    //     list = list.filter(item => filteredChoosed.kashruyot.includes(item.kashrut.kashrut_name));
    // } 