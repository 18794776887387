<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6">
                <div style="margin-bottom: 20px;">
                    <span>{{ message }}</span>
                </div>
                <div style="margin-bottom: 20px;">
                    <v-btn @click="cleanCache()">ניקוי זיכרון זמני</v-btn>
                </div>
                <v-form @submit.prevent="submitHandler" ref="form">
                    <v-radio-group v-model="selectedOption" row>
                        <v-radio label="הוספת עיר" value="city"></v-radio>
                        <v-radio label="הוספת מדינה" value="country"></v-radio>
                    </v-radio-group>
                    <div style="margin-bottom: 20px;">
                        <span>הוסף שם בעברית</span>
                        <div style="margin-bottom: 20px; display: flex;">
                            <v-text-field style="max-width: 300px;" v-model="newCityOrCountryHe" label="שם העיר בעברית"
                                :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                        </div>
                    </div>
                    <div style="margin-bottom: 20px;">
                        <span>הוסף שם באנגלית</span>
                        <div style="margin-bottom: 20px; display: flex;">
                            <v-text-field style="max-width: 300px;" v-model="newCityOrCountryEn" label="שם המדינה בעברית"
                                :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                        </div>
                    </div>
                    <v-btn @click="addCityOrCountry()">הוסף</v-btn>
                </v-form>
            </v-col>
            <v-col cols="12" md="6">
                <div style="margin-bottom: 20px;">
                    <span>{{ message2 }}</span>
                </div>
                <div style="margin-bottom: 20px;">
                    <span>הגדרת סינון קבוע לפי כשרויות</span>
                </div>
                <v-select v-model="selectedKashruyot" :items="formattedKashruyot" label="בחר כשרויות" multiple chips
                    :item-text="lang" item-value="key"></v-select>
                <v-btn style="margin-left: 15px; margin-right: 15px;"
                    @click="createOrRemoveFavoritesKashruyot('create')">שמור</v-btn>
                <v-btn @click="createOrRemoveFavoritesKashruyot('remove')">איפוס</v-btn>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>
import ApiServices from '@/services/api.service'
import Constants from '@/util/GetConstant'

export default {
    data() {
        return {
            message: "",
            message2: "",
            activeTab: 0,
            adminSettings: true,
            newCityOrCountryHe: "",
            newCityOrCountryEn: "",
            selectedOption: "city",
            selectedKashruyot: [],
            kashruyotOptions: {},
            lang: this.$i18n.locale,
        }
    },
    computed: {
        // Convert the kashruyotOptions object into an array for the v-select
        formattedKashruyot() {
            return Object.keys(this.kashruyotOptions).map(key => ({
                key: key,  // The dynamic key name (e.g., 'badatz_mehadrin_rubin')
                ...this.kashruyotOptions[key] // Merge the content (he and en) into the object
            }));
        }
    },
    methods: {
        async cleanCache() {
            try {
                let api = process.env.VUE_APP_BASE_URL + "/admin/settings_clean_cache";
                const token = localStorage.getItem("token");
                const res = await fetch(api, ApiServices.requestOptions("POST", "", token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.message = "שגיאה!";
                    this.hideMessage();
                } else if (res.status === 200) {
                    this.message = "בוצע בהצלחה";
                    this.hideMessage();
                }
            } catch (error) {
                this.message = error.message;
                this.hideMessage();
            }
        },
        async addCityOrCountry() {
            try {
                if (this.$refs.form.validate()) {
                    let api = process.env.VUE_APP_BASE_URL + "/admin/settings_add_city_or_country_name";
                    const token = localStorage.getItem("token");
                    const jsonBody = JSON.stringify({ nameHe: this.newCityOrCountryHe, nameEn: this.newCityOrCountryEn, type: this.selectedOption });
                    const res = await fetch(api, ApiServices.requestOptions("POST", jsonBody, token));
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.message = "שגיאה!";
                        this.hideMessage();
                    } else if (res.status === 200) {
                        this.message = "בוצע בהצלחה";
                        this.newCityOrCountryHe = "";
                        this.newCityOrCountryEn = "";
                        this.hideMessage();
                    }
                }
            } catch (error) {
                this.message = error.message;
            }
        },
        hideMessage() {
            setTimeout(() => {
                this.message = "";
                this.message2 = "";
            }, 4000);
        },
        async getConstans() {
            if (this.$store.state.constant?.categories?.restaurant) {
                this.kashruyotOptions = this.$store.state.constant.kashruyot
            } else {
                const constants = await Constants.getConstant();
                if (!constants) {
                    this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
                }
                this.kashruyotOptions = constants.kashruyot;
                this.$store.state.constant = constants;
            }
        },
        async createOrRemoveFavoritesKashruyot(action) {
            try {
                let api = process.env.VUE_APP_BASE_URL + "/user/add_or_remove_favorites_kashruyot";
                const token = localStorage.getItem("token");
                const jsonBody = JSON.stringify({ action, list: this.selectedKashruyot });
                const res = await fetch(api, ApiServices.requestOptions("POST", jsonBody, token));
                if (res.status >= 400) {
                    this.message2 = "שגיאה!";
                    this.hideMessage();
                } else if (res.status === 200) {
                    this.message2 = "בוצע בהצלחה";
                    if (action === "remove") {
                        this.selectedKashruyot = [];
                    }
                    this.hideMessage();
                }
            } catch (error) {
                this.message = error.message;
            }
        },
        async getUserFavoritesKashruyot() {
            try {
                let api = process.env.VUE_APP_BASE_URL + "/user/get_user_favorites_kashruyot";
                const token = localStorage.getItem("token");
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.message2 = "שגיאה!";
                    this.hideMessage();
                } else if (res.status === 200) {
                    this.message2 = "בוצע בהצלחה";
                    this.selectedKashruyot = jsonObject.favorites_kashruyot;

                    this.hideMessage();
                }
            } catch (error) {
                this.message = error.message;
            }
        }
    },
    mounted() {
        this.getConstans();
        this.getUserFavoritesKashruyot();
    },
}
</script>
<style scoped>
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    margin-right: 5px;
    margin-left: 5px;
}
</style>