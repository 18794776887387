<template>
    <v-expansion-panels accordion focusable flat style="max-width: 100%; ">
        <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
            <v-expansion-panel-header>{{ $t('home.filter_kashrut_title') }}</v-expansion-panel-header>
            <v-expansion-panel-content>
                <div style="margin-bottom: 10px;" v-if="!Object.values(constantsChoosed.kashruyot).some(item => item)">
                    <span style="font-weight: bold;">{{ $t('home.filter_show_all_title') }}</span>
                </div>
                <v-checkbox v-for="(label, key) in constants.kashruyot" v-model="constantsChoosed.kashruyot[key]" :key="key"
                    :label="label[lang]" :value="label" @change="onCheckboxChange(label, key, 'kashruyot')"
                    style="margin-bottom: 0px !important; margin-top: 0px !important;">
                </v-checkbox>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
            <v-expansion-panel-header>{{ $t('home.filter_type_title') }}</v-expansion-panel-header>
            <v-expansion-panel-content>
                <div style="margin-bottom: 10px;" v-if="!Object.values(constantsChoosed.types).some(item => item)">
                    <span style="font-weight: bold;">{{ $t('home.filter_show_all_title') }}</span>
                </div>
                <v-checkbox v-for="(label, key) in constants.types" v-model="constantsChoosed.types[key]" :key="key"
                    :label="label[lang]" :value="label" @change="onCheckboxChange(label, key, 'types')"
                    style="margin-bottom: 0px !important; margin-top: 0px !important;">
                </v-checkbox>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
            <v-expansion-panel-header>{{ $t('home.filter_category_title') }}</v-expansion-panel-header>
            <v-expansion-panel-content>
                <div style="margin-bottom: 10px;" v-if="!Object.values(constantsChoosed.categories).some(item => item)">
                    <span style="font-weight: bold;">{{ $t('home.filter_show_all_title') }}</span>
                </div>
                <v-checkbox v-for="(label, key) in constants.categories" v-model="constantsChoosed.categories[key]"
                    :key="key" :label="label[lang]" :value="label" @change="onCheckboxChange(label, key, 'categories')"
                    style="margin-bottom: 0px !important; margin-top: 0px !important;">
                </v-checkbox>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
            <v-expansion-panel-header>{{ $t('home.filter_sub_category_title') }}</v-expansion-panel-header>
            <v-expansion-panel-content>
                <div style="margin-bottom: 10px;" v-if="!Object.values(constantsChoosed.sub_categories).some(item => item)">
                    <span style="font-weight: bold;">{{ $t('home.filter_show_all_title') }}</span>
                </div>
                <v-checkbox v-for="(label, key) in constants.sub_categories"
                    @change="onCheckboxChange(label, key, 'sub_categories')" v-model="constantsChoosed.sub_categories[key]"
                    :key="key" :label="label[lang]" :value="label"
                    style="margin-bottom: 0px !important; margin-top: 0px !important;">
                </v-checkbox>
            </v-expansion-panel-content>
        </v-expansion-panel>

    </v-expansion-panels>
</template>
<script>

export default {
    props: {
        constantsChoosed: Object,
        constants: Object,
        lang: String,
    },
    mounted() {
    },
    methods: {
        onCheckboxChange(label, key, filterType) {
            if (this.constantsChoosed[filterType][key]) {
                // Checkbox selected, add the label to the object
                this.$set(this.constantsChoosed[filterType], key, label);
            } else {
                // Checkbox unselected, remove the key from the object
                this.$delete(this.constantsChoosed[filterType], key);
            }
            console.log(this.constantsChoosed);
            this.$emit('filterChanged');
        },
    }
}
</script>
   