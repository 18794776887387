<template>
  <div class="g">
    <FormLogin v-if="loginComp" @register="openRegister" />
    <FormRegister v-if="registerComp" @login="openLogin" />
  </div>
</template>
<script>
import FormLogin from '@/components/auth/FormLogin.vue'
import FormRegister from '@/components/auth/FormRegister.vue'
import Auth from '@/services/auth.service.js'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Vue from 'vue'

export default {
  components: {
    FormLogin,
    FormRegister
  },
  data() {
    return {
      loginComp: true,
      registerComp: false
    }
  },
  methods: {
    openRegister() {
       this.registerComp = true;
       this.loginComp = false;
    },
    openLogin() {
      this.registerComp = false;
       this.loginComp = true;
    },
  },
  mounted() {
    Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
})
  },
}
</script>
<style>
.g {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: rgb(238, 242, 246);
  align-items: center;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
  transform: translateZ(0);
  animation: pages_colorIn__r4Ogf 2.5s ease-out;
}

@keyframes pages_colorIn__r4Ogf {
  0% {
    filter: grayscale(1);
  }

  100% {
    filter: grayscale(0);
  }
}
</style>