// i18n.js
import VueI18n from 'vue-i18n';
import Auth from './services/auth.service.js'; // adjust the import path as needed
import Vue from 'vue';

Vue.use(VueI18n)

import homeEn from './locales/en/home.json';
import homeHe from './locales/he/home.json';
import navEn from './locales/en/nav.json';
import navHe from './locales/he/nav.json';
import aboutEn from './locales/en/about.json';
import aboutHe from './locales/he/about.json';
import restDetailsEn from './locales/en/restDetails.json';
import restDetailsHe from './locales/he/restDetails.json';
import cardEn from './locales/en/card.json';
import cardHe from './locales/he/card.json';
import filterDialogEn from './locales/en/filterDialog.json';
import filterDialogHe from './locales/he/filterDialog.json';
import authEn from './locales/en/auth.json';
import authHe from './locales/he/auth.json';
import termsEn from './locales/en/terms.json';
import termsHe from './locales/he/terms.json';

export const messages = {
  en: {
    home: homeEn,
    nav: navEn,
    about: aboutEn,
    restDetails: restDetailsEn,
    card: cardEn,
    filterDialog: filterDialogEn,
    auth: authEn,
    terms: termsEn
  },
  he: {
    home: homeHe,
    nav: navHe,
    about: aboutHe,
    restDetails: restDetailsHe,
    card: cardHe,
    filterDialog: filterDialogHe,
    auth: authHe,
    terms: termsHe
  },
};

// // Create an instance of i18n
const i18n = new VueI18n({
  locale: window.location.href.startsWith('https://en.') ? 'en' : 'he', // Default language
  fallbackLocale: 'he', // Fallback language
  messages: messages // Initially empty, to be loaded dynamically
});

export default i18n;