<template>
    <v-app style="background-color: rgb(238, 242, 246);">
        <v-main>
            <div
                style="height: 50px; margin-top: 50px; background-color: #dae1eb; display: flex; justify-content: center; align-items: center;">
                <v-tabs v-model="activeTab" centered background-color="#dae1eb" height="50px">
                    <v-tab v-for="tab in tabs" :key="tab" class="text-center" @click="setComponent(tab)">
                        <span style="font-weight: bold;">{{ tab }}</span>
                    </v-tab>
                </v-tabs>
            </div>
            <AddRestaurant v-if="addRest"></AddRestaurant>
            <EditRestaurant v-if="editRest"></EditRestaurant>
            <userSettings v-if="userSettings"></userSettings>
        </v-main>
    </v-app>
</template>
<script>
import AddRestaurant from '@/components/user/AddRestaurant'
import EditRestaurant from '@/components/user/EditRestaurant'
import userSettings from '@/components/user/SettingsComp'
import Auth from '@/services/auth.service.js'

export default {
    components: {
        AddRestaurant,
        EditRestaurant,
        userSettings,
    },
    data() {
        return {
            addRest: false,
            editRest: false,
            userSettings: true,
            activeTab: 0,
            tabs: ["הגדרות לקוח", "הוספת מסעדה חדשה"]
        }
    },
    mounted() {
        let auth = Auth.checkAuth();
        if (!auth) {
            this.$router.replace({ name: "home" })
        }
        this.updatePageTitle();
        if (this.$route.params._id) {
            this.editRest = true;
            this.addRest = false;
        }
    },
    methods: {
        updatePageTitle() {
            if (this.$vuetify.rtl) {
                return document.title = 'כשר בסביבה | אזור אישי'
            } else {
                return document.title = 'Kosher Basviva | Account';
            }
        },
        setComponent(tab) {
            if (tab == "הגדרות לקוח") {
                this.addRest = false;
                this.editRest = false;
                this.userSettings = true;
            } else if (tab == "הוספת מסעדה חדשה") {
                this.addRest = true;
                this.editRest = false;
                this.userSettings = false;
            }
        }
    }
};
</script>