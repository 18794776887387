<template>
    <v-row justify="center">
        <v-dialog v-model="openMode" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="openMode = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title style="text-transform: none;">{{$t('filterDialog.filter_title')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items style="margin-inline-end: 10px;">
                        <v-btn dark text @click="ressetChoosed()" style="text-transform: none;">
                            {{$t('filterDialog.reset')}}
                        </v-btn>
                    </v-toolbar-items>
                    <v-toolbar-items>
                        <v-btn dark text @click="searchFiltered()" style="text-transform: none;">
                            {{$t('filterDialog.filter_and_search')}}
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-expansion-panels accordion focusable multiple style="max-width: 100%; ">
                    <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
                        <v-expansion-panel-header> {{$t('filterDialog.kashrut')}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div style="margin-bottom: 10px;"
                                v-if="!Object.values(constantsChoosed.kashruyot).some(item => item)">
                                <span style="font-weight: bold;">{{$t('filterDialog.show_all')}}</span>
                            </div>
                            <v-checkbox v-for="(label, key) in constants.kashruyot"
                                v-model="constantsChoosed.kashruyot[key]" :key="key" :label="label[lang]" :value="label"
                                style="margin-bottom: 0px !important; margin-top: 0px !important;">
                            </v-checkbox>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
                        <v-expansion-panel-header>{{$t('filterDialog.type')}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div style="margin-bottom: 10px;"
                                v-if="!Object.values(constantsChoosed.types).some(item => item)">
                                <span style="font-weight: bold;">{{$t('filterDialog.show_all')}}</span>
                            </div>
                            <v-checkbox v-for="(label, key) in constants.types"
                                v-model="constantsChoosed.types[key]" :key="key" :label="label[lang]" :value="label"
                                style="margin-bottom: 0px !important; margin-top: 0px !important;">
                            </v-checkbox>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
                        <v-expansion-panel-header>{{$t('filterDialog.category')}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div style="margin-bottom: 10px;"
                                v-if="!Object.values(constantsChoosed.categories).some(item => item)">
                                <span style="font-weight: bold;">{{$t('filterDialog.show_all')}}</span>
                            </div>
                            <v-checkbox v-for="(label, key) in constants.categories"
                                v-model="constantsChoosed.categories[key]" :key="key" :label="label[lang]" :value="label"
                                style="margin-bottom: 0px !important; margin-top: 0px !important;">
                            </v-checkbox>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel style="border-bottom: 0.5px solid #cfcccc;">
                        <v-expansion-panel-header>{{$t('filterDialog.sub_category')}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div style="margin-bottom: 10px;"
                                v-if="!Object.values(constantsChoosed.sub_categories).some(item => item)">
                                <span style="font-weight: bold;">{{$t('filterDialog.show_all')}}</span>
                            </div>
                            <v-checkbox v-for="(label, key) in constants.sub_categories"
                                v-model="constantsChoosed.sub_categories[key]" :key="key" :label="label[lang]" :value="label"
                                style="margin-bottom: 0px !important; margin-top: 0px !important;">
                            </v-checkbox>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                </v-expansion-panels>


            </v-card>
        </v-dialog>

    </v-row>
</template>
<script>
export default {
    props: {
        constantsChoosed: Object,
        lang: String,
        constants: Object,
        value: { type: Boolean, default: false },

    },
    data: () => ({
    }),

    methods: {

        searchFiltered() {
            this.$emit('searchFiltered')
            this.openMode = false;
        },
        ressetChoosed() {
            this.$emit('ressetChoosed')
        }

    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
}
</script>
<style></style>
    