import ApiServices from '@/services/api.service'

class GetConstant {
  constructor() {
  }

  static async getConstant() {

    try {
      let api = process.env.VUE_APP_BASE_URL + "/web/get_constants";
      const res = await fetch(api, ApiServices.requestOptions("GET", "", ""));
      const jsonObject = await res.json();
      if (res.status === 400) {
        return false
      } else if (res.status === 200) {
        return jsonObject
      }
    } catch (error) {
      return false
    }

  }

  static async getSearchAutoCompleteList() {
    try {
      let api = process.env.VUE_APP_BASE_URL + "/web/get_search_auto_complete";
      const res = await fetch(api, ApiServices.requestOptions("GET", "", ""));
      const jsonObject = await res.json();

      if (res.status === 400) {
        return false;
      } else if (res.status === 200) {
        console.log(22222);
        const combinedArray = [];

        for (const key in jsonObject) {
          if (jsonObject.hasOwnProperty(key)) {
            const values = jsonObject[key];

            // Check if values is an object (cities, countries, etc.)
            if (typeof values === 'object' && !Array.isArray(values)) {
              for (const nestedKey in values) {
                if (values.hasOwnProperty(nestedKey)) {
                  const item = values[nestedKey]; // Access nested object (he, en)
                  // Add a new field "key" to the item, containing the original nestedKey
                  const itemWithKey = { ...item, key: nestedKey };
                  // Push the modified item to the combinedArray
                  combinedArray.push({ [key]: itemWithKey });
                }
              }
            } else if (Array.isArray(values)) {
              // For any keys that still contain an array of strings (if any)
              for (const value of values) {
                if (value !== "") {
                  const item = { [key]: value };
                  combinedArray.push(item);
                }
              }
            }
          }
        }
        console.log(33333);
        console.log(combinedArray);
        return combinedArray;
      }
    } catch (error) {
      console.error('Error in getSearchAutoCompleteList:', error);
      return false;
    }
  }



}

export default GetConstant;


