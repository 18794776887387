import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RestDetailsView from '../views/RestDetailsView.vue'
import LoginView from '../views/auth/LoginView.vue'
import UserView from '../views/UserView.vue'
import AdminView from '../views/admin/AdminView.vue'
import RessetPassView from '../views/auth/ResetPasswordView.vue'
import VerifyEmailView from '../views/auth/VerifyEmailView.vue'
import StatisticsView from '../views/admin/StatisticsView.vue'
import LogsView from '../views/admin/LogsView.vue'
// import { loadLanguageAsync } from '../services/translator'; // Import the language loader
import store from '@/store'; // Import your Vuex store

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/list'
  },
  {
    path: '/list',
    name: 'home',
    component: HomeView,
    beforeEnter: (to, from, next) => {
      if (from.name !== "restaurant-details") {
        // its regular enter, reset scroll position and item per load
        store.commit('currentScrollTop', 0); // For page scroll
        store.commit('itemsPerLoad', 20); // For page scroll
      }
      // else its back from details and needs to return to exact position
      next();
    }
    // meta: { keepAlive: true } // Ensure this is active
  },
  {
    path: '/restaurant-details/:id',
    name: 'restaurant-details',
    component: RestDetailsView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/resset-password/:token',
    name: 'ressetPass',
    component: RessetPassView,
  },
  {
    path: '/verify-email/:token',
    name: 'verifyEmail',
    component: VerifyEmailView,
  },
  {
    path: '/user-account',
    name: 'userAccount',
    component: UserView,
  },
  {
    path: '/logs',
    name: 'logs',
    component: LogsView,
  },
  {
    path: '/admin-statistics',
    name: 'statistics',
    component: StatisticsView,
  },
  {
    path: '/admin-account',
    name: 'adminAccount',
    component: AdminView,

  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


export default router
